import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import './Menu.css';
import {close} from "ionicons/icons";
import React from "react";

interface AppPage {
  url: string;
  title: string;
  small: boolean;
  line: boolean
}

const appPages: AppPage[] = [
  {
    title: 'Mijn bewijzen',
    url: '/page/Mijn bewijzen',
    small: false,
    line: false
  },
  {
    title: 'Bewijs toevoegen',
    url: '/offline',
    small: false,
    line: false
  },
  {
    title: 'Meestgestelde vragen',
    url: '/offline',
    small: false,
    line: true
  },
  {
    title: 'Papieren bewijs toevoegen',
    url: '/offline',
    small: true,
    line: false
  },
  {
    title: 'Over deze app',
    url: '/offline',
    small: true,
    line: false
  }
];

const labels = [];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay" swipeGesture={false}>
      <IonContent>
        <IonList className="closeList">
          <IonItem  lines="none" detail={false}>
            <IonMenuToggle autoHide={false}>
              <IonIcon icon={close} size="large"></IonIcon>
            </IonMenuToggle>

          </IonItem>
        </IonList>

        <IonList>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={appPage.small === true ? 'small' : (appPage.line ? 'line' : '')} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
