import './OverviewContainer.css';
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonCardContent,
    IonButton,
    IonLabel,
    IonRouterLink,
    IonInput,
    IonSegment,
    IonSegmentButton,
} from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { calendar, personCircle, location, people, map, informationCircle } from 'ionicons/icons';
import React, {useEffect, useState} from "react";
import moment from 'moment';
import 'moment/locale/nl'
import { Lottie } from '@alfonmga/react-lottie-light-ts'

interface ContainerProps {
    name: string;
}

const OverviewContainer: React.FC<ContainerProps> = ({ name }) => {

    const [showPane, setShowPane] = useState("domestic");
    const [pin, setPin] = useState<string>();
    const [error, setError] = useState<string>();
    const [guid, setGuid] = useState<string>(() => {
        let guid = localStorage.getItem("guid");
        return guid !== null
            ? guid
            : "";
    });
    const [qrData, setQrData] = useState<any>(() => {
        const qrData = localStorage.getItem("qrData");
        return qrData !== null
            ? JSON.parse(qrData)
            : {validFrom : ""};
    });

    async function getQrDataFromApi() {
        const url = new URL(window.location.href);
        const guid = url.pathname.substr(1);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ pin: pin })
        }
        const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost/pwa/api/";
        await fetch(apiUrl + guid, requestOptions).then((response) => {
            if (response.status >= 400 && response.status < 600) {
                throw new Error(String(response.status));
            }
            return response.json();
        }).then((returnedResponse) => {
            localStorage.setItem('qrData', JSON.stringify(returnedResponse));
            localStorage.setItem('guid', guid);
            setQrData(JSON.stringify(returnedResponse));
            setGuid(guid);
            window.location.reload();
        }).catch((errorResponse) => {
            // Your error is here!
            setError("PIN invalid - " + errorResponse);
        });
    }


    useEffect(() => {
        if (guid) {
            const url = new URL(window.location.href);
            const guidUrl = url.pathname.substr(1);
            if(guidUrl != guid) {
                // reset it
                localStorage.removeItem('qrData');
                localStorage.removeItem('guid');
                setQrData("");
                setGuid("");
            }
        }
        if(qrData && qrData.validFrom && qrData.validForHours) {
            let validUntilTs = qrData.validFrom + (qrData.validForHours * 60 * 60);
            let validUntil = moment(parseInt(validUntilTs) * 1000);
            let now = moment();
            if (now.isAfter(validUntil)) {
                // reset it
                localStorage.removeItem('qrData');
                localStorage.removeItem('guid');
                setQrData("");
                setGuid("");
            }
        }
    });

    return (
        <div className="overviewContainer">

            <IonItem color="tertiary" class="enterPin" className={qrData && guid ? "qrDataAvailable" : "qrDataNotSet"}>
                <div className="inner">
                    <p><strong>Before entering your PIN, add this website to your homescreen and disable auto rotate when using the app!<br /><br/>Enter PIN to unlock:<br /><br /></strong></p>
                    <IonInput onIonChange={e => setPin(e.detail.value!)}></IonInput><br />
                    <p>{error}</p>
                    <IonButton onClick={() => getQrDataFromApi()} color="secondary">Unlock</IonButton>
                </div>
            </IonItem>

            <div className="hiddenLottie">
                <img src="/assets/images/no-internet.png" />
                <Lottie config={{path: "/assets/skatefiets.json", loop: true}}></Lottie>
            </div>

            <div className="wrapSegment">
                <IonSegment onIonChange={e => setShowPane(e.detail.value!)}>
                    <IonSegmentButton value="domestic" className={showPane == "domestic" ? "segment-button-checked" : ""}>
                        <IonLabel>Nederland</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="international">
                        <IonLabel>Internationaal</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </div>

            <div className={showPane == "domestic" ? "show-pane" : "hidden-pane"} >
                <p className="intro">Je kunt een bewijs toevoegen als je bent gevaccineerd of hersteld. Of als je bent getest bij een <IonRouterLink routerLink="/offline">aangesloten testlocatie</IonRouterLink>. De app maakt een Nederlands en een internationaal bewijs.</p>
                <IonCard>
                    <IonCardHeader>
                        <div className="holder">
                            <img src="/assets/images/domestic.svg" />
                            <IonCardTitle>Mijn bewijs</IonCardTitle>
                        </div>
                    </IonCardHeader>
                    <IonCardContent>
                        <p>Vaccinatiebewijs: <br />
                            geldig vanaf { moment(parseInt(qrData.validFrom) * 1000).locale("nl").format('D MMMM YYYY') }
                        </p>
                        <IonButton routerLink="/qr">Bekijk QR</IonButton>
                    </IonCardContent>
                </IonCard>
            </div>

            <div className={showPane == "international" ? "show-pane" : "hidden-pane"}>
                <p className="intro">Ben je in het buitenland of ga je de grens over? Gebruik dan altijd jouw internationale bewijs. Controleer voor vertrek welk bewijs je nodig hebt.</p>
                <p className="intro"><IonRouterLink routerLink="/offline"><br />Welk bewijs is er nodig op mijn bestemming?</IonRouterLink></p>
                <IonCard className="empty">
                    <IonCardHeader>
                        <img src="/assets/images/international.svg" />
                        <IonCardTitle>Hier komt jouw internationale bewijs</IonCardTitle>
                    </IonCardHeader>

                </IonCard>
            </div>

        </div>
    );
};

export default OverviewContainer;
