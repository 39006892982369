import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonBackButton,
    IonFooter,
    IonPage,
    IonTitle,
    IonToolbar,
    IonModal
} from '@ionic/react';
import { arrowBackOutline, close } from 'ionicons/icons';
import React, { useState } from 'react';

import { useParams } from 'react-router';
import QrContainer from '../components/QrContainer';
import './QrPage.css';

const QrPage: React.FC = () => {


    const [showModal, setShowModal] = useState(false);
    const { name } = useParams<{ name: string; }>();
    const [qrData, setQrData] = useState<any>(() => {
        const qrData = localStorage.getItem("qrData");
        return qrData !== null
            ? JSON.parse(qrData)
            : {firstNameInitial: "", lastNameInitial: "", birthDay: "", birthMonth: 12};
    });

    const months : string[] = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OKT",
        "NOV",
        "DEC",
    ];

  return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/page/Mijn bewijzen" icon={arrowBackOutline} text=""></IonBackButton>
                </IonButtons>
                <IonTitle>Nederlandse QR</IonTitle>
                <IonButtons slot="end">
                    <IonButton className="detailsBlue" onClick={() => setShowModal(true)}>Details</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
      <IonContent scrollY={false} >
        <IonModal isOpen={showModal} cssClass='modal-class'>
            <div className="modalContent">
                <IonIcon onClick={() => setShowModal(false)} icon={close} size="large"></IonIcon>
                <h3>Over mijn<br />Nederlandse QR-code</h3>
                <p>In jouw Nederlandse QR-code staan minimale gegevens. In de QR-code staat dat je een geldig bewijs hebt. Niet of je een test of vaccinatie hebt gedaan.</p>
                <p>Om zeker te zijn dat de QR-code bij jou hoort staan er enkele gegevens in de QR-code. Het gaat om de eerste letter van je voornaam, de eerste letter van je achternaam, je geboortedag en geboortemaand.</p>
                <p>Jouw gegevens: {qrData.firstNameInitial} {qrData.lastNameInitial} {qrData.birthDay} {months[qrData.birthMonth - 1]}</p>
                <p className="lastp">* Soms vallen gegevens weg om je privacy te beschermen. Je ziet dan een streepje (-).</p>

            </div>
        </IonModal>
        <QrContainer name={name}  />
      </IonContent>
    </IonPage>
  );
};

export default QrPage;
