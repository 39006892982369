import './QrContainer.css';
import React, {useEffect, useState} from "react";
import { Lottie } from '@alfonmga/react-lottie-light-ts'

interface ContainerProps {
  name: string;
}

const QrContainer: React.FC<ContainerProps> = ({ name }) => {

    const [isInverted, setInverted] = useState(false);
    const [noSeconds, setNoSeconds] = useState(0);
    const [qrData, setQrData] = useState<any>(() => {
        const qrData = localStorage.getItem("qrData");
        return qrData !== null
            ? JSON.parse(qrData)
            : {qrCode: ""};
    });

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setInverted(!isInverted)
    };

    useEffect(() => {
        setTimeout(() => {
            setNoSeconds(noSeconds + 30);
        }, 30000);
    });

    return (
        <div className="container">
                <p>
                    <img className={`qr qr_${noSeconds % 120}`} src={qrData.qrCode} />
                </p>
                <div onClick={handleClick} className="bottom">
                    <Lottie className={isInverted == true ? "inverted" : ""} config={{path: "/assets/skatefiets.json", loop: true}}></Lottie>
                </div>
        </div>
  );
};

export default QrContainer;
