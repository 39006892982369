import './OfflineContainer.css';
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonButton
} from '@ionic/react';

interface ContainerProps {
    name: string;
}

const OfflineContainer: React.FC<ContainerProps> = ({ name }) => {
    return (
        <div className="container-offline">
            <img src="/assets/images/no-internet.png" />
            <h1>Je hebt geen internetverbinding</h1>
            <p>Om deze functionaliteit te gebruiken heb je verbinding nodig met internet.</p>
        </div>
    );
};

export default OfflineContainer;
