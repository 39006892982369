import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonBackButton,
    IonFooter,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { arrowBack, star, addOutline } from 'ionicons/icons';

import { useParams } from 'react-router';
import OverviewContainer from '../components/OverviewContainer';
import './Page.css';

const Page: React.FC = () => {

    const { name } = useParams<{ name: string; }>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{name}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton routerLink="/offline"><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollY={false} >
                <OverviewContainer name={name} />
            </IonContent>
        </IonPage>
    );
};

export default Page;
