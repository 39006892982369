import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonBackButton,
    IonFooter,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {arrowBackOutline } from 'ionicons/icons';

import { useParams } from 'react-router';
import OfflineContainer from '../components/OfflineContainer';
import './OfflinePage.css';
import React from "react";

const OfflinePage: React.FC = () => {

    const { name } = useParams<{ name: string; }>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Geen internet</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/page/Mijn bewijzen" icon={arrowBackOutline} text=""></IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollY={false} >

                <OfflineContainer name={name} />
            </IonContent>
        </IonPage>
    );
};

export default OfflinePage;
